import {createApp} from 'vue';
import App from './App.vue';
import {router} from '@/router';
import scroll from 'vue-seamless-scroll';

let app = createApp(App)

app.config.globalProperties.$routerP = '';
app.config.productionTip = false;


app.use(router);
app.use(scroll);

app.mount('#app');