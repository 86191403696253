<!--页脚-->
<template>
  <div class="footer">
    <div class="content">
      <div class="logo">
        <img src="./img/logo-S-W.png" alt="">
      </div>
      <div class="information">
        <div class="information_title">
          <div class="information_title_name">公司名称</div>
          北京航星传动科技有限公司
        </div>
        <div class="information_address">
          <div class="information_address_name">公司地址</div>
          北京市大兴区亦庄经济技术开发区科创十四街6号赛蒂 <br>国际工业园一号楼东6层
        </div>
        <div class="information_phone">
          <div class="information_phone_name">联系方式</div>
          15321003782
        </div>
        <div class="information_email">
          <div class="information_email_name">电子邮箱</div>
          tandi@starservor.com
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OFooter"
}
</script>

<style scoped lang="less">
@import "./Footer";
</style>