<template>
  <router-view/>
  <Footer v-if="isShowFooter"/>
</template>

<script>
import {reactive, toRefs, watchEffect} from "vue";
import {useRouter} from "vue-router";
import Footer from "@/components/Footer/Footer";

export default {
  name: 'App',
  components: {
    Footer
  },
  setup() {
    document.title = '航星传动';
    const data = reactive({
      isShowFooter: false
    });
    const router = useRouter();

    watchEffect(() => {
      data.isShowFooter = router.currentRoute.value.path !== '/';
    });

    return {
      ...toRefs(data)
    };
  }
}
</script>

<style>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  font-size: .14rem;
  color: #333;
  box-sizing: border-box;
}

ul, li {
  list-style: none;
}
</style>
