import {createRouter, createWebHistory} from "vue-router";

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: () => import("@/components/Home/Home"),
  },
  // 企业文化
  {
    path: '/Company-Culture',
    name: 'CompanyCulture',
    component: () => import("@/components/Company-Culture/CompanyCulture")
  },
  // 项目介绍
  {
    path: '/Project',
    name: 'Project',
    component: () => import("@/components/Project/Project")
  },
  // 竞争优势
  {
    path: '/Compete',
    name: 'Compete',
    component: () => import("@/components/Compete/Compete")
  },
  // 产品服务
  {
    path: '/ProductService',
    name: 'ProductService',
    component: () => import("@/components/ProductService/ProductService"),
    children: [
      {
        path: 'p01',
        name: 'Product01',
        component: () => import('@/components/ProductService/Product01/Product01')
      },
      {
        path: 'p02',
        name: 'Product02',
        component: () => import('@/components/ProductService/Product02/Product02')
      },
      {
        path: 'p03',
        name: 'Product03',
        component: () => import('@/components/ProductService/Product03/Product03')
      },
      {
        path: 'p04',
        name: 'Product04',
        component: () => import('@/components/ProductService/Product04/Product04')
      }
    ]
  },
  // 招贤纳士
  {
    path: '/Recruitment',
    name: 'Recruitment',
    component: () => import("@/components/Recruitment/Recruitment")
  },
  // 关于我们
  {
    path: '/About',
    name: 'About',
    component: () => import("@/components/About/About")
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
});